












































import { Component, Prop, Vue } from 'vue-property-decorator';

import { Role } from '@minddocdev/lib-common/users';
import { postResetPassword } from '@minddocdev/lib-web/api/services/users';
import { getAppForGroup } from '@minddocdev/lib-web/browser/url.utils';
import PasswordInput from '@minddocdev/lib-web/components/inputs/PasswordInput.vue';
import { isOTPExpired } from '@minddocdev/lib-web/utils/auth.utils';
import OTPExpired from '@minddocdev/ui-accounts/components/OTPExpired.vue';

import { addBreadcrumb, captureException } from '@sentry/vue';
import { extend } from 'vee-validate';

@Component({
  components: {
    PasswordInput,
    OTPExpired,
  },
})
export default class ForgotPassword extends Vue {
  @Prop({ required: true, type: String }) otp!: string;

  newPassword = '';
  newPasswordCheck = '';
  errorMessage = '';
  loading = false;
  otpIsExpired = false;

  created() {
    this.initComponent();
  }

  initComponent() {
    // We need to add a rule to verify equality between 2 inputs
    // See https://logaretm.github.io/vee-validate/advanced/cross-field-validation.html#targeting-other-fields
    /* istanbul ignore next */
    extend('equal', {
      params: ['target'],
      validate(value: string, { target }: any) {
        return value === target;
      },
      message: this.$t('confirmError') as string,
    });
    this.otpIsExpired = isOTPExpired(this.otp);
    /* istanbul ignore next */
    if (this.otpIsExpired) {
      this.$router.push({ name: 'forgot-password' });
    }
  }

  async onSubmit() {
    addBreadcrumb({
      category: 'reset-password',
      message: 'Resetting password.',
    });
    this.loading = true;
    try {
      await postResetPassword(this.otp, this.newPassword);
      const parsedJSON = JSON.parse(atob(this.otp));
      const { group, roles } = parsedJSON;

      if (roles?.includes(Role.UnguidedDigaClient)) {
        const subdomain = window.location.hostname.split('.')[0];
        window.location.href =
          subdomain === 'accounts-diga'
            ? 'https://accounts-diga.staging.minddoc.com/'
            : 'https://accounts.diga.minddoc.com/';
      } else {
        const continueUrl = getAppForGroup(group, window.location.hostname);
        window.location.href = continueUrl;
      }
    } catch (e) {
      /* istanbul ignore next */
      if (e.response?.status >= 500) {
        captureException(e);
      }
      this.errorMessage = this.$t('error') as string;
    }
    this.loading = false;
  }
}
